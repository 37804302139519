body
  background-color: $background
  color: $color1
  text-align: justify
  hyphens: auto
  hyphenate-limit-chars: 10
  line-height: 1.3
  font-size: 1.2em
  padding-top: 2em

footer
  & a
    margin: 0.5em
  & svg
    margin: auto
  text-align: center
  margin-top: 1em

h1, h2, h3, h4
  color: $yellow
  hyphens: none
  text-align: left

a
  color: lighten($color2, 20%)
  svg
    height: 1em
    fill: lighten($color2, 20%)

a:hover
  color: $color2-alt
  svg
    fill: $color2-alt

nav
  position: fixed
  top: 0
  left: 0
  border-bottom: 3px solid black
  width: 100vw
  background: darken($background, 4%)
  z-index: 10
  a
    margin: 5px 0.5rem
    color: lighten($blue, 10%)
    &:hover
      color: $cyan
      svg
        fill: $cyan
    &.active
      color: $color1-alt
    svg
      height: 1rem
      fill: lighten($blue, 10%)
  & ul
    list-style: none
    padding: 0
    display: block
    width: fit-content
    margin: 0.5em auto
    & > li
      display: inline
      position: relative
      & > ul
        display: none
        li
          display: list-item
      &:hover > ul
        display: block
        z-index: 2
        position: absolute
        left: 0
        margin-top: -1px
        margin-left: 0.5rem
        padding: 0.2lh
        border: 1px solid black
        width: max-content
        background-color: darken($background, 3%)

img
  max-width: 100%
  display: block
  &.emoji
    display: inline

.site-container
  width: 750px
  margin: auto

figure.highlight, div.highlight
  margin: 15px
  padding: 0px 5px
  pre
    font-size: 0.85em
    overflow-x: auto

.post-details
  margin: 5px
  font-size: 0.8em
  &>p
    margin: 0.5rem

.project-details a
  margin: 0.5rem

.post-banner
  width: inherit
  &>img
    width: inherit
    height: auto
    margin: auto

iframe
  width: 100%

ul.post-list
  list-style: none
  padding: 0 5px
  &>li
    border-bottom: 1px solid $base0
    &>h3,&>.post-details
      margin: 5px

.link_tag
  display: inline-block
  border: 1px solid lighten($blue, 10%)
  border-radius: 0.3rem
  padding: 0.2rem
  font-size: 0.7rem

code.language-plaintext
  background-color: rgba(128,128,128,0.25)

.MJX-TEX
  background-color: rgba(128,128,128,0.2)

#markdown-toc
  border: 1px solid lighten($color2, 20%)
  padding: 2rem
  padding-left: 4rem
  width: fit-content
  margin: 2rem
  ol
    padding-left: 1rem

@media (min-width: 768px)
  #menu_main
    display: block
    & > li
      display: inline
  #menu_mobile
    display: none

@media (max-width: 768px)
  .site-container
    width: 95vw
    code
      overflow-wrap: break-word
  .post-details
    font-size: 0.9em
    &>p
      margin: 0.5em 0
  #markdown-toc
    padding: 1rem
    padding-left: 2rem
    margin: 1rem
  nav
    #menu_main
      display: none
    &:hover > #menu_main
      display: block
